import { FC, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import Breadcrumb from '../BreadCrumb/BreadCrumb';

type PageHeaderType = {
  title: ReactNode;
  componentSpecificClass?: string;
  disabled?: boolean;
};

const BreadcrumbItem = [
  {
    key: 1,
    title: 'Define',
  },
  {
    key: 2,
    title: 'Review',
  },
  {
    key: 3,
    title: 'Activate',
  },
];

const PageHeader: FC<PageHeaderType> = ({
  title,
  componentSpecificClass,
  disabled,
}) => {
  const { pathname } = useLocation();

  const isAudiencePath = (pathname: string) =>
    ['/audience/create', '/review', 'define', 'activate'].some((substring) =>
      pathname.includes(substring)
    );

  const getActiveIndexes = () => {
    if(pathname.includes('define') || pathname.includes('create')) return 1
    else if(pathname.includes('review')) return 2;
    else if(pathname.includes('activate')) return 3;
    else return 0;
  }

  return (
    <div className="pageHeaderWrap">
      <h1
        className={`pageHeader${
          componentSpecificClass ? ' ' + componentSpecificClass : ''
        }`}
      >
        {title}
        {isAudiencePath(pathname) && (
          <Breadcrumb
            items={BreadcrumbItem}
            activeIndex={getActiveIndexes()}
            disabled={disabled}
          />
        )}
      </h1>
    </div>
  );
};

export default PageHeader;
