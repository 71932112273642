import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LeftPanel } from "../LeftNavPanel";


const AudienceActivationPage: FC = () => {
  document.title = 'Audience Activation - BranchLab';

  const { audId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (audId) {
      navigate(`/audience/${audId}/activate`)
    } 
  }, [audId]);
  

  return (
    <div className="audienceSetupPage audienceActivation">
      <LeftPanel defaultSelectedKeys={['3']} />
    </div>
  );
};

export default AudienceActivationPage;
