import React, { Fragment, useEffect, useState } from 'react';
import { PageHeader } from '../../../sharedComponent';
import PixelListTable from './PixelListTable';
import { Button, ConfigProvider } from 'antd';
import {
  IfilterProps,
  ILoaderState,
  IPixelListingParam,
} from '../../../../models/interfaces';
import { usePostApiInterceptor, useRedirection } from '../../../../hooks';
import { useLoaderStore } from '../../../../stores';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';
import GlobalFilterModal from '../../../sharedComponent/GlobalFilterModal/GlobalFilterModal';
import { FilterMessage } from '../../../sharedComponent/FilterMessage';
import { filter } from '../../../../assets/images';

type PixelBodyType = {
  end_date: string | null;
  start_date: string | null;
  limit: number;
  off_set: number;
  order_column: string | null;
  order_direction: string | null;
  title: string | null;
  brand_id: string | null;
  agency_id: string | null;
  advertiser_id: string | null;
  filter_option: number | null;
  timeframe: string | null;
};

const baseListingParam = {
  sortOrder: 'DESC',
  sortColumn: 'created_on',
  pageNumber: 1,
  timeframe: null,
  rowsPerPage: 15,
  dateRange: null,
  title: null,
  filter_option: 1,
  agency: null,
  brand: null,
  advertiser: null,
};

const convertFiltersToNumberArray = (filters: any): IPixelListingParam => {
  const result: Partial<IPixelListingParam> = {
    sortOrder: baseListingParam.sortOrder,
    sortColumn: baseListingParam.sortColumn,
    pageNumber: baseListingParam.pageNumber,
    rowsPerPage: baseListingParam.rowsPerPage,
  };

  // Handle global filters
  if (filters.global) {
    if (filters.global.agency)
      result.agency = convertToNumberArray(filters.global.agency);
    if (filters.global.advertiser)
      result.advertiser = convertToNumberArray(filters.global.advertiser);
    if (filters.global.brand)
      result.brand = convertToNumberArray(filters.global.brand);
    if (filters.global.dateRange) result.dateRange = filters.global.dateRange;
    if (filters.global.timeframe) result.timeframe = filters.global.timeframe;
  }

  // Handle activation filters
  if (filters.pixel) {
    if (filters.pixel.title) result.title = filters.pixel.title;
    if (filters.pixel.filter_option)
      result.filter_option = filters.activation.filter_option;
  }

  return result as IPixelListingParam;
};

const convertToNumberArray = (value: string | null): number[] | null => {
  if (!value) return null;
  return value
    .split(',')
    .map(Number)
    .filter((n) => !isNaN(n));
};

const PixelListingWrap = () => {
  const filters = JSON.parse(localStorage.getItem('Filters') || '{}');
  const { redirectPixelSetup } = useRedirection();
  const [pixelListingParam, setPixelListingParam] =
      useState<IPixelListingParam>(
        Object.keys(filters).length > 0
          ? convertFiltersToNumberArray(filters)
          : baseListingParam
      ),
    [body, setBody] = useState<PixelBodyType | null>(null),
    [isMounted, setIsMounted] = useState<boolean>(false),
    [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const { data, isLoading } = usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.PIXEL_LISTING_DATA,
    () => {
      setIsMounted(false);
    }
  );

  const { loaderState } = useLoaderStore((state: ILoaderState) => state);

  useEffect(() => {
    setIsMounted(true);
  }, [body]);

  useEffect(() => {
    setBody({
      end_date: pixelListingParam.dateRange?.length
        ? pixelListingParam.dateRange[1]
        : null,
      filter_option: pixelListingParam.filter_option,
      title: pixelListingParam.title,
      start_date: pixelListingParam.dateRange?.length
        ? pixelListingParam.dateRange[0]
        : null,
      timeframe: pixelListingParam.timeframe,
      limit: pixelListingParam.rowsPerPage,
      off_set:
        (pixelListingParam.pageNumber - 1) * pixelListingParam.rowsPerPage,
      order_column: pixelListingParam.sortColumn,
      order_direction: pixelListingParam.sortOrder,
      brand_id: pixelListingParam.brand?.length
        ? pixelListingParam.brand.toString()
        : null,
      agency_id: pixelListingParam.agency?.length
        ? pixelListingParam.agency.toString()
        : null,
      advertiser_id: pixelListingParam.advertiser?.length
        ? pixelListingParam.advertiser.toString()
        : null,
    });
  }, [pixelListingParam]);

  const applyFilters = (data: IfilterProps) => {
    let filter: PixelBodyType = {
      agency_id: data.agency?.length ? data.agency.toString() : null,
      advertiser_id: data.advertiser?.length
        ? data.advertiser.toString()
        : null,
      brand_id: data.brand?.length ? data.brand.toString() : null,
      timeframe: data.timeframe ? data.timeframe.toString() : null,
      end_date: data.dateRange?.length ? data.dateRange[1] : null,
      filter_option: data.filter_option ?? null,
      title: data.title ?? null,
      start_date: data.dateRange?.length ? data.dateRange[0] : null,
      limit: pixelListingParam.rowsPerPage,
      off_set:
        (pixelListingParam.pageNumber - 1) * pixelListingParam.rowsPerPage,
      order_column: pixelListingParam.sortColumn,
      order_direction: pixelListingParam.sortOrder,
    };
    let filterStorage = {
      ...JSON.parse(localStorage.getItem('Filters') || '{}'),
      global: {
        agency: filter.agency_id,
        advertiser: filter.advertiser_id,
        brand: filter.brand_id,
        dateRange:
          filter.start_date && filter.end_date
            ? [filter.start_date, filter.end_date]
            : null,
        timeframe: filter.timeframe,
      },
      pixel: {
        title: filter.title,
        filter_option: filter.filter_option,
      },
    };
    setPixelListingParam({
      agency: data.agency?.length ? data.agency : null,
      brand: data.brand?.length ? data.brand : null,
      advertiser: data.advertiser?.length ? data.advertiser : null,
      title: data.title ?? '',
      filter_option: data.filter_option ?? null,
      rowsPerPage: baseListingParam.rowsPerPage,
      timeframe: data.timeframe ? data.timeframe.toString() : null,
      pageNumber: baseListingParam.pageNumber,
      sortColumn: baseListingParam.sortColumn,
      sortOrder: baseListingParam.sortOrder,
      dateRange: data.dateRange?.length
        ? [data.dateRange[0], data.dateRange[1]]
        : null,
    });
    setBody(filter);
    localStorage.setItem('Filters', JSON.stringify(filterStorage));
  };

  const handleCancel = () => {
    setIsFilterModalOpen(false);
  };

  return (
    <div className="audienceCommonWrap">
      <PageHeader title="Conversion Pixels" />
      <div className="audienceListingPageContent">
        {!loaderState && (
          <Fragment>
            <div className="filterWrap">
              <div className="filterBtnAndText">
                <ConfigProvider wave={{ disabled: true }}>
                  <Button onClick={() => setIsFilterModalOpen(true)}>
                    <img src={filter} alt="" />
                  </Button>
                </ConfigProvider>
                {(pixelListingParam.agency ||
                  pixelListingParam.advertiser ||
                  pixelListingParam.brand ||
                  pixelListingParam.timeframe ||
                  pixelListingParam.dateRange ||
                  pixelListingParam.title) && (
                  <FilterMessage type="pixel" data={pixelListingParam} />
                )}

                <GlobalFilterModal
                  isOpen={isFilterModalOpen}
                  type="pixel"
                  filterData={pixelListingParam}
                  handleOk={applyFilters}
                  handleCancel={handleCancel}
                />
              </div>

              <button
                className="primaryBtn addAudienceBtn"
                onClick={() => redirectPixelSetup()}
              >
                New Pixel
              </button>
            </div>
            <PixelListTable
              pixelData={data}
              isLoading={isLoading}
              paginationAndSortingData={pixelListingParam}
              setPaginationAndSortingData={setPixelListingParam}
            />
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default PixelListingWrap;
