/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ErrorPage } from './components/pages';
import { AuthRoutes, UnAuthRoutes } from './routes';
import { ConfigProvider, Spin } from 'antd';
import { useLoaderStore, useUserInformationStore } from './stores';
import { ILoaderState, IUserInformationState } from './models/interfaces';
import { useCookies } from './hooks';

import '../src/assets/css/custom.css';
import '../src/assets/css/dev.css';
import { VerifyUser } from './components/pages/VerifyUser';
import { ForgotPassword } from './components/pages/ForgotPassword';
import { RedBanner } from './components/sharedComponent/RedBanner';

const App: FC<{}> = () => {
  const [updateCookieCounter, setUpdateCookieCounter] = useState<any>(null);

  const { createCookie, deleteCookie } = useCookies();

  const {
      loaderState,
      updateLoaderState,
      showPageLoaderState,
      showBlankLoaderState,
    } = useLoaderStore((state: ILoaderState) => state),
    { userInformation, userIsLoggedIn } = useUserInformationStore(
      (state: IUserInformationState) => state
    );

  const cookieCreation = () => {
    deleteCookie();

    const {
      user_id,
      email,
      role,
      token,
      profile_pic,
      first_name,
      last_name,
      account_id,
      timestamp,
      privilege_set,
    } = userInformation;

    if (user_id && email && account_id && role && token) {
      const newUser = {
        user_id,
        email,
        user_role_id: role,
        user_token: token,
        profile_pic,
        first_name,
        last_name,
        user_account_id: account_id,
        timestamp,
        privilege_set,
      };
      createCookie(newUser);
    }
  };

  useEffect(() => {
    if (userIsLoggedIn) {
      setUpdateCookieCounter(
        setInterval(
          cookieCreation,
          1000 *
            60 *
            10 *
            (Number(process.env.REACT_APP_SESSION_ACTIVE_DAY ?? 7) * 24 * 6 - 1)
        )
      );
      return;
    }

    if (!userIsLoggedIn && updateCookieCounter) {
      clearInterval(updateCookieCounter);
      setUpdateCookieCounter(null);
    }
  }, [userIsLoggedIn]);

  useEffect(() => {
    const bodyElement = document.querySelector('body');

    if (
      loaderState ||
      updateLoaderState ||
      showPageLoaderState ||
      showBlankLoaderState
    ) {
      if (!bodyElement?.classList.contains('disableScroll')) {
        bodyElement?.classList.add('disableScroll');
      }
    } else if (bodyElement?.classList.contains('disableScroll')) {
      bodyElement?.classList.remove('disableScroll');
    }
  }, [
    loaderState,
    updateLoaderState,
    showPageLoaderState,
    showBlankLoaderState,
  ]);

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: '"Inter", sans-serif',
        },
      }}
    >
      {
        process.env.REACT_APP_ENV === 'dev' && 
      <RedBanner message="WARNING! This is the DEVELOPMENT environment!" />
      }
      <BrowserRouter>
        <Routes>
          {AuthRoutes}
          {UnAuthRoutes}
          <Route
            key="Forgot-Password"
            path="/forgot-password"
            element={<ForgotPassword />}
          />
          <Route
            key="Verify"
            path="/update-password"
            element={<VerifyUser />}
          ></Route>
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default App;
