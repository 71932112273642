import { NavLink, useParams } from 'react-router-dom';
import { Button, Input, Table, TableColumnsType } from 'antd';
import {
  CommonElementLabel,
  ModalComponent,
  PageHeader,
} from '../../../sharedComponent';
import { FC, Fragment, useEffect, useState } from 'react';
import {
  usePostApiInterceptor,
  useRedirection,
  useNotificationMessage,
} from '../../../../hooks';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';
import {
  useCheckIfDataModifiedStore,
  useLoaderStore,
  useUserInformationStore,
} from '../../../../stores';
import {
  ICheckIfDataModifiedState,
  IMasterAdvertiserDDState,
  IMasterAgencyDDState,
  IMasterBrandDDState,
  IPixelDataCheckState,
  IUserInformationState,
} from '../../../../models/interfaces';
import { usePixelDataCheckStore } from '../../../../stores/saveAndUpdateData.store';
import { CheckCircleTwoTone, CopyOutlined } from '@ant-design/icons';
import FeatureSetup from './FeatureSetup';
import {
  useMasterAdvertiserElementStore,
  useMasterAgencyElementStore,
  useMasterBrandElementStore,
} from '../../../../stores/masterData.store';
import PixelFires from './PixelFires';
import { OrganisationModal } from '../../AccountSettings/AccountSettingsComponent';
import CustomEmptyTableComponent from '../../../sharedComponent/Empty/CustomEmptyTableComponent';
import dayjs from 'dayjs';

type TorganisationBody = {
  organization_type: string | null;
  id: number;
  name: string;
};

interface IAudienceList {
  audience_type: string;
  audience_id: number;
  title: string;
  status: string;
  created_on: string;
}

const PixelSetupFieldWrap: FC<{
  pixel_data: any;
}> = ({ pixel_data }) => {
  const { id } = useParams();
  const { redirectPixelListing } = useRedirection();
  const { userInformation } = useUserInformationStore(
      (state: IUserInformationState) => state
    ),
    { ifDataModified, setIfDataModified } = useCheckIfDataModifiedStore(
      (state: ICheckIfDataModifiedState) => state
    ),
    { setIfUpdated } = usePixelDataCheckStore(
      (state: IPixelDataCheckState) => state
    ),
    { agencyDDElements, updateAgencyDDElements } = useMasterAgencyElementStore(
      (state: IMasterAgencyDDState) => state
    ),
    { brandDDElements, updateBrandDDElements } = useMasterBrandElementStore(
      (state: IMasterBrandDDState) => state
    ),
    { advertiserDDElements, updateAdvertiserDDElements } =
      useMasterAdvertiserElementStore(
        (state: IMasterAdvertiserDDState) => state
      );
  const { setLoaderState, updateLoaderState } = useLoaderStore();
  const [title, setTitle] = useState<string>(''),
    [body, setBody] = useState<any>(''),
    [domain, setDomain] = useState<string>(''),
    [isMounted, setIsMounted] = useState<boolean>(false),
    [agency, setAgency] = useState<null | string>(null),
    [brand, setBrand] = useState<null | string>(null),
    [advertiser, setAdvertiser] = useState<null | string>(null),
    [audience_List, setAudience_List] = useState<IAudienceList[]>([]),
    [modalToOpenForCancel, setModalToOpenForCancel] = useState<boolean>(false),
    [isDataSaving, setIsDataSaving] = useState<boolean>(false),
    [pixelFireBody, setPixelFireBody] = useState<{ id: string } | null>(null),
    [pixelMounted, setPixelMounted] = useState(false),
    [isfireLoading, setIsfireLoading] = useState(false),
    [pixelFireData, setPixelFireData] = useState<any>([]),
    [pixel_Id, setPixel_Id] = useState<null | number>(null),
    [pixel_Code, setPixel_Code] = useState<string | null>(null),
    [isCopied, setIsCopied] = useState<boolean>(false),
    [isCopiedImage, setIsCopiedImage] = useState<boolean>(false),
    [messageObj, setMessageObj] = useState<any>({ isShowing: false }),
    [isInvalidUrl, setIsInvalidUrl] = useState<boolean>(false),
    [organisationType, setOrganisationType] = useState<string | null>(null),
    [openOrganisation, setOpenOrganisation] = useState<boolean>(false),
    [fieldVal, setFieldVal] = useState<string>(''),
    [orgainsationMounted, setOrgainsationMounted] = useState<boolean>(false),
    [orgainsationBody, setOrgainsationBody] =
      useState<TorganisationBody | null>(null),
    [organisationId, setOrganisationId] = useState<number>(0);

  const { context, destroyMessage } = useNotificationMessage(messageObj);

  const handleCancel = () => {
    ifDataModified ? setModalToOpenForCancel(true) : redirectPixelListing();
  };

  useEffect(() => {
    body && setIsMounted(true);
  }, [body]);

  usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.SAVE_PIXEL_DATA,
    (data: any, error: any) => {
      setLoaderState(false);
      setBody('');
      setIsMounted(false);
      setIsDataSaving(false);
      if (data && !error) {
        if (data.pixel_code.length) {
          setPixel_Id(data.pixel_id);
          setPixel_Code(data.pixel_code);
        }
        updateErrorMessage('success', 'Pixel is saved');
        resetAllLocalState();
      } else {
        updateErrorMessage('error', 'Error occurred while saving Pixel.');
      }
    }
  );

  usePostApiInterceptor(
    pixelMounted,
    pixelFireBody,
    RoutesEnum.GET_PIXEL_FIRE,
    (data: any, error: any) => {
      setPixelMounted(false);
      setIsfireLoading(false);
      setPixelFireBody(null);
      if (data && !error) {
        setPixelFireData(data);
      } else {
        updateErrorMessage('error', 'Error occurred while getting Pixel Fires');
        setIsDataSaving(false);
      }
    }
  );

  const handleSave = () => {
    let validated = isValidUrl(domain);
    if (validated) {
      setIsDataSaving(true);
      const pixelData = {
        user_id: userInformation.user_id,
        title,
        pixel_domain: domain,
        pixel_id: pixel_Id,
        agency_id: agency,
        brand_id: brand,
        advertiser_id: advertiser,
      };
      setLoaderState(true);
      setIfUpdated(false);
      destroyMessage('save');
      setMessageObj({ isShowing: false });
      setBody(pixelData);
    } else {
      setIsInvalidUrl(true);
    }
  };

  const getStatusColumnValueJsx = (value: string) => {
    switch (value) {
      case 'Building':
        return (
          <span
            className={`statusChip`}
            style={{ backgroundColor: '#FFF598', color: '#645A00' }}
          >
            {value}
          </span>
        );
      case 'Estimated':
        return (
          <span
            className={`statusChip`}
            style={{ backgroundColor: '#F0F9FF', color: '#026AA2' }}
          >
            {value}
          </span>
        );
      case 'Activated':
        return (
          <span
            className={`statusChip`}
            style={{ backgroundColor: '#ECFDF3', color: '#2D912B' }}
          >
            {value}
          </span>
        );
    }
  };

  useEffect(() => {
    setIsInvalidUrl(false);
  }, [domain]);

  const resetAllLocalState = () => {
    setModalToOpenForCancel(false);
    setIfDataModified(false);
  };

  useEffect(() => {
    if (id && pixel_data) {
      const {
        title,
        pixel_domain,
        pixel_id,
        pixel_code,
        agency,
        brand,
        advertiser,
      } = pixel_data.pixel_info;
      setAudience_List(pixel_data.audience_list ?? []);
      setPixel_Id(pixel_id);
      setTitle(title);
      setDomain(pixel_domain);
      setPixel_Code(pixel_code);
      setAgency(agency);
      setBrand(brand);
      setAdvertiser(advertiser);
    }
  }, [id]);

  const handleDelete = () => {
    resetAllLocalState();
    redirectPixelListing();
  };

  const audColumns: TableColumnsType<IAudienceList> = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => (
        <NavLink
          to={
            record.status !== 'Activated'
              ? '/update-audience/' + record.audience_id
              : '/view-audience/' + record.audience_id
          }
          title={text}
        >
          {text}
        </NavLink>
      ),
      width: '300px',
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => getStatusColumnValueJsx(text),
      width: '300px',
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Type',
      dataIndex: 'audience_type',
      key: 'audience_type',
      width: '300px',
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Date',
      dataIndex: 'created_on',
      key: 'created_on',
      render: (text) => dayjs(text).format('M/D/YYYY'),
      width: '300px',
      ellipsis: true,
      showSorterTooltip: false,
    },
  ];

  function extractParenthesesContent(inputString: string) {
    const start = inputString.indexOf('(');
    const end = inputString.indexOf(')');
    return start !== -1 && end !== -1
      ? inputString.slice(start + 1, end)
      : inputString;
  }

  function manipulateDataArray(dataArray: any) {
    dataArray?.forEach((item: any) => {
      item.audience_type = extractParenthesesContent(item.audience_type);
    });
    return dataArray;
  }

  useEffect(() => {
    if (pixel_Code) {
      setPixelFireBody({ id: pixel_Code.toString() });
      setIsfireLoading(true);
      setPixelMounted(true);
    }
  }, [pixel_Code]);

  const updateErrorMessage = (type: string, messagecontent: string) => {
    setMessageObj({
      key: 'save',
      isShowing: true,
      type,
      messagecontent,
      duration: 5,
    });
  };

  useEffect(() => {
    if (isCopied || isCopiedImage) {
      setTimeout(() => {
        setIsCopied(false);
        setIsCopiedImage(false);
      }, 1000);
    }
  }, [isCopied, isCopiedImage]);

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_PIXEL_URL}${pixel_Code}`
    );
    setIsCopied(!isCopied);
  };

  const copyImage = () => {
    navigator.clipboard.writeText(
      `<img src=${process.env.REACT_APP_PIXEL_URL}${pixel_Code} height='1px' width='1px' />`
    );
    setIsCopiedImage(!isCopiedImage);
  };

  const urlRegex = new RegExp(
    /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*\.(com|org|net|co|co\.uk|[a-z]{2,})(\/.*)?$/i
  );

  function isValidUrl(url: string): boolean {
    return urlRegex.test(url);
  }

  const getOrganizationType = () => {
    if (organisationType === 'advertiser') return 'Advertiser';
    else if (organisationType === 'agency') return 'Agency';
    else return 'Brand';
  };

  usePostApiInterceptor(
    orgainsationMounted,
    orgainsationBody,
    RoutesEnum.SAVE_ACCOUNT_ORGANIZATION,
    (data: any, error: any) => {
      if (data && !error) {
        updateErrorMessage('success', `${getOrganizationType()} is saved`);
        if (organisationType === 'advertiser') {
          updateAdvertiserDDElements(orgainsationBody?.name ?? '', data.org_id);
          setAdvertiser(data.org_id);
        } else if (organisationType === 'agency') {
          updateAgencyDDElements(orgainsationBody?.name ?? '', data.org_id);
          setAgency(data.org_id);
        } else {
          updateBrandDDElements(orgainsationBody?.name ?? '', data.org_id);
          setBrand(data.org_id);
        }
      } else {
        updateErrorMessage(
          'error',
          data.msg
            ? `${data.msg}`
            : `Error occurred while saving ${getOrganizationType()}.`
        );
      }
      setOrgainsationBody(null);
      setOrgainsationMounted(false);
    }
  );

  const handleSaveOrganisation = (value: string) => {
    setOrgainsationBody({
      organization_type: organisationType,
      id: organisationId,
      name: value,
    });
    setOrgainsationMounted(true);
  };

  return (
    <Fragment>
      <div className="audienceCommonWrap">
        {context}
        <PageHeader title={(id ? 'Update' : 'Create') + ' Pixel'} />
        <div className="audienceActivationTable">
          {!updateLoaderState && (
            <FeatureSetup
              defaultTitle={title}
              setTitle={setTitle}
              defaultDomain={domain}
              setDomain={setDomain}
              valid={isInvalidUrl}
              agencyDDElements={agencyDDElements}
              brandDDElements={brandDDElements}
              advertiserDDElements={advertiserDDElements}
              agency={agency}
              setAgency={setAgency}
              brand={brand}
              setBrand={setBrand}
              advertiser={advertiser}
              setAdvertiser={setAdvertiser}
              pixelId={pixel_Code}
              setOrganisationType={setOrganisationType}
              openOrganisation={setOpenOrganisation}
            />
          )}
          <div className="sectionHeader">
            <h2>Associated Audiences</h2>
          </div>
          <Table
            columns={audColumns}
            dataSource={manipulateDataArray(audience_List) ?? []}
            className={`antTableElement audienceListTable mb-20`}
            locale={{
              emptyText: (
                <CustomEmptyTableComponent message="There are no audiences attached to this pixel." />
              ),
            }}
            pagination={false}
            scroll={audience_List.length > 0 ? { x: 'max-content' } : undefined}
          />
          <div className="sectionHeader">
            <h2>Pixel Code</h2>
          </div>
          {(id || pixel_Code) && !updateLoaderState && (
            <div className="measuremntPixelSec pixelUrlSec">
              <div className="informationText">
                <CommonElementLabel label="Copy the options below and send it to the owner of the website it is to be placed on:" />
              </div>

              <div>
                <div className="mb-20">
                  <CommonElementLabel label="Raw URL" />
                  <Input
                    placeholder="Raw URL"
                    readOnly
                    value={`${process.env.REACT_APP_PIXEL_URL}${pixel_Code}`}
                    suffix={
                      <div onClick={handleCopy} className="copyAction">
                        {isCopied ? (
                          <CheckCircleTwoTone twoToneColor="#52c41a" />
                        ) : (
                          <CopyOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        )}
                      </div>
                    }
                  />
                </div>
                <div className="mb-20">
                  <CommonElementLabel label="Image" />
                  <Input
                    placeholder="Image"
                    readOnly
                    value={`<img src=${process.env.REACT_APP_PIXEL_URL}${pixel_Code} height='1px' width='1px' />`}
                    suffix={
                      <div onClick={copyImage} className="copyAction">
                        {isCopiedImage ? (
                          <CheckCircleTwoTone twoToneColor="#52c41a" />
                        ) : (
                          <CopyOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        )}
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          )}
          {(id || pixel_Code) && !updateLoaderState && (
            <div className="activationTableWrap">
              <div className="sectionHeader">
                <h2>Recent Pixel Activity</h2>
              </div>
              <PixelFires isLoading={isfireLoading} data={pixelFireData} />
            </div>
          )}
          {openOrganisation && (
            <OrganisationModal
              type={
                organisationType === 'agency'
                  ? 'agency'
                  : organisationType === 'brand'
                  ? 'brand'
                  : 'advertiser'
              }
              isOpen={openOrganisation}
              onClose={() => setOpenOrganisation(false)}
              onSave={handleSaveOrganisation}
              value={fieldVal}
              setVal={setFieldVal}
              setId={setOrganisationId}
            />
          )}
        </div>
      </div>
      {!updateLoaderState && (
        <div className="newFeatureSetBtn">
          <ModalComponent
            modalHeader={'Cancel ' + (id ? 'Update' : 'Create') + ' Pixel'}
            modalToOpen={modalToOpenForCancel}
            setModalToOpen={setModalToOpenForCancel}
            deleteSetParams={{
              name: 'Cancel ' + (id ? 'Update' : 'Create') + ' Pixel',
              handelOk: handleDelete,
              message:
                'You’re about to lose any unsaved changes. Do you want to continue?',
              okText: 'Yes',
              cancelText: 'No',
            }}
          />
          <Button className="noBGBtn" size="large" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            loading={isDataSaving}
            disabled={
              !title ||
              !domain ||
              !agency ||
              !brand ||
              !advertiser ||
              !ifDataModified
            }
            size="large"
            className="primaryBtn"
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      )}
    </Fragment>
  );
};

export default PixelSetupFieldWrap;
