import React, { useEffect } from 'react';
import { LeftPanel } from '../LeftNavPanel';
import { useNavigate, useParams } from 'react-router-dom';

const UpdateAudiencePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      navigate(`/audience/${id}/define`);
    }
  }, [id]);

  return (
    <div className="audienceSetupPage audienceActivation">
      <LeftPanel defaultSelectedKeys={['1', '2']} />
    </div>
  );
};

export default UpdateAudiencePage;
