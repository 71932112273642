import { create } from 'zustand';
import {
  IAudienceSaveMethods,
  IAudienceSetUpState,
  IAudienceState,
  ICaregiverFeatureState,
  IGlobalFeatureState,
  IHcpFeatureState,
} from '../models/interfaces/store.interface';
import {
  ICareGiverFeatureSetBaseData,
  // IFeatureSetBaseData,
  IFeatureSetDataForMessage,
  // IFeatureSetElement,
  IGlobalFeatureSetBaseData,
  IHcpFeatureSetBaseData,
} from '../models/interfaces';

export const useAudienceSetUp = create<IAudienceSetUpState>((set) => ({
  audienceTitle: '',
  setAudienceTitle: (value: string) => {
    set({ audienceTitle: value });
  },
  audienceAgency: null,
  setAudienceAgency: (value: number | null) => {
    set({ audienceAgency: value });
  },
  audienceBrand: null,
  setAudienceBrand: (value: number | null) => {
    set({ audienceBrand: value });
  },
  audiencePageNumber: 1,
  setAudiencePageNumber: (value: number) => {
    set({ audiencePageNumber: value });
  },
  selectedAudienceType : null,
  setSelectedAudienceType: (value :number | null) => {
    set({ selectedAudienceType: value });
  },
  advertiser: null,
  setAdvertiser: (value: number | null) => {
    set({ advertiser: value });
  },
  audienceId: null,
  setAudienceId: (value: string | null) => {
    set({ audienceId: value });
  },
  emptyFilter: false,
  setEmptyFilter: (value: boolean) => {
    set({ emptyFilter: value });
  },
}));

export const useAudienceSaveMethods = create<IAudienceSaveMethods>((set) => ({
  isSaveInitiated: false,
  setIsSaveInitiated: (value: boolean) => {
    set({ isSaveInitiated: value });
  },
}));

export const useAudienceSets = create<IAudienceState>((set) => ({
  isDataSaved: false,
  setIsDataSaved: (value: boolean) => {
    set({ isDataSaved: value });
  },
  isDataLoading: true,
  setIsDataLoading: (value: boolean) => {
    set({ isDataLoading: value });
  },
  selectedSavingOpt: '1',
  setSelectedSavingOpt: (value: string) => {
    set({ selectedSavingOpt: value });
  },
  deletedIds: null,
  setDeletedIds: (value: number[] | null) => {
    set({ deletedIds: value });
  },
  defineFeatureCount: 0,
  setDefineFeatureCount: (data: number) => {
    set({ defineFeatureCount: data });
  },

  defineFeatureData: [],
  setDefineFeatureData: (data: any) => {
    set({ defineFeatureData: data });
  },
  isCancelled: false,
  setIsCancelled: (value: boolean) => {
    set({ isCancelled: value });
  },
  firstFeatureNumber: null,
  setFirstFeatureNumber: (value: number) => {
    set({ firstFeatureNumber: value });
  },
  defineFeatureSet: {},
  setDefineFeatureSet: (value: any) => {
    set({ defineFeatureSet: value });
  },

  updateDefineFeatureTargeting: (value: number, featureSetNumber: number) => {
    set((state: any) => {
      return {
        defineFeatureSet: {
          ...state.defineFeatureSet,
          [featureSetNumber]: {
            ...state.defineFeatureSet[featureSetNumber],
            targeting: [value],
          },
        },
      };
    });
  },

  updateDefineFeatureValues: (
    value: any,
    key: string,
    type: boolean,
    featureSetNumber: number
  ) => {
    set((state: any) => {
      if (type) {
        return {
          defineFeatureSet: {
            ...state.defineFeatureSet,
            [featureSetNumber]: {
              ...state.defineFeatureSet[featureSetNumber],
              featureSetValues: {
                ...state.defineFeatureSet[featureSetNumber].featureSetValues,
                [key]: [...value],
              },
            },
          },
        };
      } else {
        return {
          defineFeatureSet: {
            ...state.defineFeatureSet,
            [featureSetNumber]: {
              ...state.defineFeatureSet[featureSetNumber],
              featureSetValues: {
                ...state.defineFeatureSet[featureSetNumber].featureSetValues,
                [key]: value,
              },
            },
          },
        };
      }
    });
  },

  updateDefineOperator: (value: number, featureSetNumber: number) => {
    set((state: any) => {
      return {
        defineFeatureSet: {
          ...state.defineFeatureSet,
          [featureSetNumber]: {
            ...state.defineFeatureSet[featureSetNumber],
            operator: value,
          },
        },
      };
    });
  },

  audienceSummary: '',
  setAudienceSummary: (value: string) => {
    set({ audienceSummary: value });
  },
  featureSetDataForMessagePrev: '',
  setFeatureSetDataForMessagePrev: (value: string) => {
    set({ featureSetDataForMessagePrev: value });
  },
}));

export const useGlobalFeatureSet = create<IGlobalFeatureState>((set) => ({
  globalFeatureSet: {
    timeFrame: null,
    dateRange: null,
    patientGender: null,
    selectedAge: [],
    selectedRaceAndEthnicity: [],
    selectedGeographyDmas: [],
    selectedGeographyStates: [],
    language: [],
  },
  setGlobalFeatureSet: (value: IGlobalFeatureSetBaseData) => {
    set({ globalFeatureSet: value });
  },
  updateGlobalFeatureSet: (value: any, key: string, type: boolean) => {
    set((state: any) => {
      if (type) {
        return {
          globalFeatureSet: {
            ...state.globalFeatureSet,
            [key]: [...value],
          },
        };
      } else {
        return {
          globalFeatureSet: {
            ...state.globalFeatureSet,
            [key]: value,
          },
        };
      }
    });
  },
  globalTargeting: [],
  setGlobalTargeting: (value: number[]) => {
    set({ globalTargeting: value });
  },
  updateGlobalTargeting: (value: number) => {
    set((state: any) => {
      return {
        globalTargeting: [...state.globalTargeting, value],
      };
    });
  },
}));

export const useHcpFeatureSet = create<IHcpFeatureState>((set) => ({
  hcpFeatureSet: {
    selectedProviderSpecialty: [],
    customNPIList: [],
    selectedProviderTaxonomy: [],
  },
  setHcpFeatureSet: (value: IHcpFeatureSetBaseData) => {
    set({ hcpFeatureSet: value });
  },
  updateHcpFeatureSet: (value: any, key: string, type: boolean) => {
    set((state: any) => {
      if (type) {
        return {
          hcpFeatureSet: {
            ...state.hcpFeatureSet,
            [key]: [...value],
          },
        };
      } else {
        return {
          hcpFeatureSet: {
            ...state.hcpFeatureSet,
            [key]: value,
          },
        };
      }
    });
  },
  hcpTargeting: [],
  setHcpTargeting: (value: number[]) => {
    set({ hcpTargeting: value });
  },
  updateHcpTargeting: (value: number) => {
    set((state: any) => {
      return {
        hcpTargeting: [...state.hcpTargeting, value],
      };
    });
  },
}));

export const useCareGiverFeatureSet = create<ICaregiverFeatureState>((set) => ({
  caregiverFeatureSet: {
    caregiverGender: null,
    caregiverAge: [],
    selectedGeographyDmas: [],
    selectedGeographyStates: [],
  },
  setCaregiverFeatureSet: (value: ICareGiverFeatureSetBaseData) => {
    set({ caregiverFeatureSet: value });
  },
  updateCaregiverFeatureSet: (value: any, key: string, type: boolean) => {
    set((state: any) => {
      if (type) {
        return {
          caregiverFeatureSet: {
            ...state.caregiverFeatureSet,
            [key]: [...value],
          },
        };
      } else {
        return {
          caregiverFeatureSet: {
            ...state.caregiverFeatureSet,
            [key]: value,
          },
        };
      }
    });
  },
  caregiverTargeting: [],
  setCaregiverTargeting: (value: number[]) => {
    set({ caregiverTargeting: value });
  },
  updateCaregiverTargeting: (value: number) => {
    set((state: any) => {
      return {
        caregiverTargeting: [...state.caregiverTargeting, value],
      };
    });
  },
}));
