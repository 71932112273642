import React, { useEffect, useState } from 'react';
import PageHeader from '../../../sharedComponent/PageHeader/PageHeader';
import DefaultFeatureSet from './DefaultFeatureSet';
import { useParams } from 'react-router-dom';
import {
  useCheckIfDataModifiedStore,
  useFeatureSetDataCheckStore,
  useFeatureSetDataHandlingStore,
  useMasterAudienceTypeElementsStore,
} from '../../../../stores';
import {
  useMasterAdvertiserElementStore,
  useMasterAgencyElementStore,
  useMasterBrandElementStore,
  useMasterPixelElementsStore,
  useMasterTargetingElementsStore,
} from '../../../../stores/masterData.store';
import {
  IAudienceSetUpState,
  IAudienceState,
  ICheckIfDataModifiedState,
  IEachDropdownElementsType,
  IFeatureSetDataCheckState,
  IFeatureSetDataState,
  IMasterAdvertiserDDState,
  IMasterAgencyDDState,
  IMasterAudienceTypeDDState,
  IMasterBrandDDState,
  IMasterPixelDDState,
  IMasterTargetingDDState,
} from '../../../../models/interfaces';
import {
  useAudienceSets,
  useAudienceSetUp,
} from '../../../../stores/audienceSets.store';
import { OrganisationModal } from '../../AccountSettings/AccountSettingsComponent';
import {
  useNotificationMessage,
  usePostApiInterceptor,
} from '../../../../hooks';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';
import TargetableFeatureSetup from './TargetableFeatureSetup';
import EstimateAlert from './EstimateAlert';

type TOrganization = 'agency' | 'brand' | 'advertiser';

type TOrganizationBody = {
  organization_type: string | null;
  id: number;
  name: string;
};

const DefineAudience = () => {
  const { id } = useParams();
  document.title =
    (id ? 'Define Audience ' : 'Create Audience ') + '- BranchLab';

  const [selectedAdditionalOpts, setSelectedAdditionalOpts] =
      useState<boolean>(false),
    [organizationMounted, setOrganizationMounted] = useState<boolean>(false),
    [organizationBody, setOrganizationBody] =
      useState<TOrganizationBody | null>(null),
    [deletingSetId, setDeletingSetId] = useState<number[] | null>(null),
    [prevSetId, setPrevSetId] = useState<number>(0),
    [selectedStatus, setSelectedStatus] = useState<number | null>(1),
    [pixelFieldData, setPixelFieldData] = useState<any>(''),
    [audienceId, setAudienceId] = useState<number>(0),
    [modalToOpenForCancel, setModalToOpenForCancel] = useState<boolean>(false),
    [pixelName, setPixelName] = useState<string | null>(null),
    [pixelId, setPixelId] = useState<number>(0),
    [organizationId, setOrganizationId] = useState<number>(0),
    [fieldVal, setFieldVal] = useState<string>(''),
    [messageObj, setMessageObj] = useState<any>({ isShowing: false }),
    [openOrganization, setOpenOrganization] = useState<boolean>(false),
    [organizationType, setOrganizationType] = useState<TOrganization>('agency'),
    [pixelData, setPixelData] = useState<any>([]),
    [focusOnTitle, setFocusOnTitle] = useState<0 | 1 | 2>(0);

  const { audienceTypeDDElements } = useMasterAudienceTypeElementsStore(
      (state: IMasterAudienceTypeDDState) => state
    ),
    { agencyDDElements, updateAgencyDDElements } = useMasterAgencyElementStore(
      (state: IMasterAgencyDDState) => state
    ),
    { brandDDElements, updateBrandDDElements } = useMasterBrandElementStore(
      (state: IMasterBrandDDState) => state
    ),
    { updatedDataSet } = useFeatureSetDataHandlingStore(
      (state: IFeatureSetDataState) => state
    ),
    { pixelDDElements } = useMasterPixelElementsStore(
      (state: IMasterPixelDDState) => state
    ),
    { setIfDataModified } = useCheckIfDataModifiedStore(
      (state: ICheckIfDataModifiedState) => state
    ),
    { ifUpdated } = useFeatureSetDataCheckStore(
        (state: IFeatureSetDataCheckState) => state
      ),
    { advertiserDDElements, updateAdvertiserDDElements } =
      useMasterAdvertiserElementStore(
        (state: IMasterAdvertiserDDState) => state
      ),
    {
      advertiser,
      setAdvertiser,
      audienceTitle,
      audienceBrand,
      audienceAgency,
      setAudienceAgency,
      setAudienceBrand,
      setAudienceTitle,
      selectedAudienceType,
      setSelectedAudienceType
    } = useAudienceSetUp((state: IAudienceSetUpState) => state),
    { setTargetingDDElementsForFs } = useMasterTargetingElementsStore(
      (state: IMasterTargetingDDState) => state
    ),
    {
      setAudienceSummary,
      setFirstFeatureNumber,
    } = useAudienceSets((state: IAudienceState) => state);

  const { context } = useNotificationMessage(messageObj);

  useEffect(() => {
    if (pixelDDElements.length > 0) {
      const hasNoneDefault = pixelDDElements.some(
        (item: IEachDropdownElementsType) => item.value === 1000
      );

      if (!hasNoneDefault) {
        setPixelData([
          ...pixelDDElements,
          {
            value: 1000,
            label: 'None (Default)',
            order_id: 1000,
          },
        ]);
      } else {
        setPixelData(pixelDDElements);
      }
    }
  }, [pixelDDElements]);
  
  useEffect(() => {
    if (ifUpdated) {
      const {
        additional_option_id,
        pixel_name,
        feature_sets,
        audience_type_id
      } = updatedDataSet;
      setAudienceSummary(
        feature_sets?.length ? feature_sets[0]?.feature_set_summary : ''
      );
      setSelectedAudienceType(audience_type_id)
      setPixelName(pixel_name);
      setSelectedAdditionalOpts(additional_option_id);
    }
  }, [ifUpdated]);

  useEffect(() => {
    if (selectedAudienceType === 2) {
      setSelectedAdditionalOpts(false);
    }
  }, [selectedAudienceType]);

  useEffect(() => {
    return () => {
      setAudienceId(0);
      setSelectedAudienceType(null);
      setSelectedStatus(1);
      setSelectedAdditionalOpts(false);
      setFirstFeatureNumber(0);
      setPrevSetId(0);
      setPixelData([]);
      setPixelFieldData([]);
      setDeletingSetId(null);
      setTargetingDDElementsForFs([]);
      setModalToOpenForCancel(false);
    };
  }, []);

  useEffect(() => {
    if (pixelName && pixelData.length > 0) {
      const filteredPixel = pixelData?.filter(
        (pix: any) => pix.label === pixelName
      );
      let temp = {
        label: filteredPixel[0]?.label ?? '',
        value: filteredPixel[0]?.label ?? '',
      };
      setPixelId(filteredPixel[0].value);
      setPixelFieldData(temp);
    }
  }, [pixelName, pixelData]);

  usePostApiInterceptor(
    organizationMounted,
    organizationBody,
    RoutesEnum.SAVE_ACCOUNT_ORGANIZATION,
    (data: any, error: any) => {
      if (data && !error) {
        updateErrorMessage(
          'success',
          `${
            organizationType.charAt(0).toUpperCase() + organizationType.slice(1)
          } is saved`
        );
        if (organizationType === 'advertiser') {
          updateAdvertiserDDElements(organizationBody?.name ?? '', data.org_id);
          setAdvertiser(data.org_id);
        } else if (organizationType === 'agency') {
          updateAgencyDDElements(organizationBody?.name ?? '', data.org_id);
          setAudienceAgency(data.org_id);
        } else {
          updateBrandDDElements(organizationBody?.name ?? '', data.org_id);
          setAudienceBrand(data.org_id);
        }
      } else {
        updateErrorMessage(
          'error',
          data.msg
            ? `${data.msg}`
            : `Error occurred while saving ${
                organizationType.charAt(0).toUpperCase() +
                organizationType.slice(1)
              }.`
        );
      }
      setOrganizationBody(null);
      setOrganizationMounted(false);
    }
  );

  const updateErrorMessage = (type: string, messagecontent: string) => {
    setMessageObj({
      key: 'save',
      isShowing: true,
      type,
      messagecontent,
      duration: 5,
    });
  };

  const onChange = (value: any) => {
    setIfDataModified(true);
    if (value === 'None (Default)') {
      setPixelFieldData(`${value}`);
    } else {
      const filteredPixel: IEachDropdownElementsType[] = pixelDDElements.filter(
        (pix: any) => pix.value === value
      );
      setPixelFieldData(`${value}`);
      setPixelId(filteredPixel[0].order_id);
      setPixelName(filteredPixel[0].label);
    }
  };

  const handleSaveOrganization = (value: string) => {
    setOrganizationBody({
      organization_type: organizationType,
      id: organizationId,
      name: value,
    });
    setOrganizationMounted(true);
  };

  return (
    <>
      {context}
      <PageHeader
        title={
          id ? (
            <>
              Update: <span className="lessOpacityText">{audienceTitle}</span>
            </>
          ) : (
            'Create Audience'
          )
        }
      />
      <div>
        <DefaultFeatureSet
          defaultTitle={audienceTitle}
          setTitle={setAudienceTitle}
          audienceType={selectedAudienceType}
          setAudienceType={(value: any) => setSelectedAudienceType(value)}
          advertiser={advertiser}
          setAdvertiser={setAdvertiser}
          defaultAgency={audienceAgency}
          setAgency={setAudienceAgency}
          defaultBrand={audienceBrand}
          agencyDDElements={agencyDDElements}
          brandDDElements={brandDDElements}
          advertiserDDElements={advertiserDDElements}
          setBrand={setAudienceBrand}
          selectedAdditionalOpts={selectedAdditionalOpts}
          setSelectedAdditionalOpts={setSelectedAdditionalOpts}
          audienceTypeDDElements={audienceTypeDDElements}
          focusOnTitle={focusOnTitle}
          pixelData={pixelData}
          pixelFieldData={pixelFieldData}
          handlePixelChange={onChange}
          setOrganisationType={setOrganizationType}
          openOrganisation={setOpenOrganization}
          disabled={(!updatedDataSet.is_dup && updatedDataSet.audience_id)}
        />
      </div>
      <TargetableFeatureSetup
        setMessageObj={setMessageObj}
        setFocusOnTitle={setFocusOnTitle}
        pixelId={pixelId}
        setPrevSetId={setPrevSetId}
        prevSetId={prevSetId}
        audienceId={audienceId}
        setAudienceId={setAudienceId}
        deletingSetId={deletingSetId}
        setDeletingSetId={setDeletingSetId}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        selectedAudienceType={selectedAudienceType}
        selectedAdditionalOpts={selectedAdditionalOpts}
        setSelectedAudienceType={setSelectedAudienceType}
        setModalToOpenForCancel={setModalToOpenForCancel}
        setSelectedAdditionalOpts={setSelectedAdditionalOpts}
      />

      {!updatedDataSet.estimated_info &&
        updatedDataSet.audience_id && (
          <EstimateAlert
            className="audienceAlertInfo"
            msgBody={
              <p>
                Your audience is saved but estimation is required to continue.
                Click <strong>‘Build Audience Insights’</strong> from the
                Audience Actions menu when you’re ready to finalize.
              </p>
            }
          />
        )}
      <OrganisationModal
        type={organizationType}
        isOpen={openOrganization}
        onClose={() => setOpenOrganization(false)}
        onSave={handleSaveOrganization}
        value={fieldVal}
        setVal={setFieldVal}
        setId={setOrganizationId}
      />
    </>
  );
};

export default DefineAudience;
