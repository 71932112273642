import { Button, Table, TableColumnsType, Tooltip } from 'antd';
import dayjs from 'dayjs';
import React, { FC } from 'react';
import CustomEmptyTableComponent from '../../../sharedComponent/Empty/CustomEmptyTableComponent';
import {
  useFeatureSetDataHandlingStore,
  useUserInformationStore,
} from '../../../../stores';
import {
  IFeatureSetDataState,
  IUserInformationState,
} from '../../../../models/interfaces';
import { RbacSettings } from '../../../../models/enums/rbacSetting';
import { SyncOutlined } from '@ant-design/icons';
import EstimateAlert from '../../AudienceSetup/AudienceSetupComponents/EstimateAlert';

interface DTCdataType {
  estimate_id: number;
  date: string;
  size: string;
  total_scored_population_size: string;
  patient_population: number;
  passing_score: number;
  status_id: number;
}

const AudienceEstimateTable: FC<{
  isLoading: boolean;
  data: any;
  disable: boolean;
  isActive: boolean;
  generateEstimate: () => void;
}> = ({ isLoading, data, disable, generateEstimate, isActive }) => {
  const { privilegeSetDDElements } = useUserInformationStore(
      (state: IUserInformationState) => state
    ),
    { updatedDataSet } = useFeatureSetDataHandlingStore(
      (state: IFeatureSetDataState) => state
    );

  const getStatusColumnValueJsx = (value: string) => {
    if (value === 'Pending') {
      return (
        <span
          className={`statusChip 1`}
          style={{ backgroundColor: '#FFF598', color: '#645A00' }}
        >
          {value}
        </span>
      );
    } else {
      return (
        <span
          className={`statusChip 1`}
          style={{ backgroundColor: '#D7EED6', color: '#2D912B' }}
        >
          {value}
        </span>
      );
    }
  };

  const dtcColumns: TableColumnsType<DTCdataType> = [
    {
      title: 'ID',
      dataIndex: 'estimate_id',
      key: 'estimate_id',
      render: (text) => <>{"#"+text}</>,
      width: '100px',
      className:'genericTableID',
      showSorterTooltip: false,
    },
    {
      title: 'Date',
      dataIndex: 'processed_at',
      key: 'processed_at',
      render: (text) => dayjs(text).format('M/D/YYYY H:mm A'),
      width: '180px',
      showSorterTooltip: false,
    },
    {
      title: 'Maximum Audience Size',
      dataIndex: 'total_scored_population_size',
      key: 'total_scored_population_size',
      width: '160px',
      showSorterTooltip: false,
    },
    {
      title: 'Total Patient Population',
      dataIndex: 'population',
      key: 'population',
      width: '180px',
      showSorterTooltip: false,
    },
    {
      title: 'Recommended Audience Size',
      dataIndex: 'size',
      key: 'size',
      width: '180px',
      showSorterTooltip: false,
      render: (text, record) => {
        return (
          <>
            <p>{text}</p>
            {data?.length && data[0].size && (
              <p style={{ color: '#ACABA5' }}>
                (Top{' '}
                {(
                  (data[0].size / data[0].total_scored_population_size) *
                  100
                ).toFixed(1)}
                %)
              </p>
            )}
          </>
        );
      },
    },
    {
      title: 'Recommended Passing Score',
      dataIndex: 'score',
      key: 'score',
      width: '180px',
      showSorterTooltip: false,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '80px',
      showSorterTooltip: false,
      render: (text) => getStatusColumnValueJsx(text),
    },
  ];

  function formatEstimatedSizes(size: number) {
    return size.toLocaleString();
  }

  function manipulateDTCArray(dataArray: DTCdataType[]) {
    return dataArray?.map((item: any) => {
      return {
        ...item,
        total_scored_population_size: !item.total_scored_population_size
          ? ''
          : formatEstimatedSizes(item.total_scored_population_size),
        size: !item.size ? '' : formatEstimatedSizes(item.size),
        population: !item.population
          ? ''
          : formatEstimatedSizes(item.population),
        score: !item.score ? '' : formatEstimatedSizes(item.score),
      };
    });
  }

  function getDaysFromDate(dateString: string): number {
    const inputDate = new Date(dateString);

    if (isNaN(inputDate.getTime())) {
      throw new Error('Invalid date string provided');
    }

    const currentDate = new Date();

    const differenceInMilliseconds = Math.abs(
      currentDate.getTime() - inputDate.getTime()
    );

    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const differenceInDays = Math.floor(
      differenceInMilliseconds / millisecondsPerDay
    );

    return differenceInDays;
  }

  return (
    <>
      <div className="sectionHeader audienceEstimate">
        <h2>Audience Estimates</h2>
        {privilegeSetDDElements.includes(RbacSettings.ACTIVATION_ADD) &&
          data.length === 0 && (
            <Button
              className="primaryBtn"
              disabled={isActive || disable}
              onClick={generateEstimate}
              size="large"
            >
              Generate Estimate
            </Button>
          )}
        {privilegeSetDDElements.includes(RbacSettings.ACTIVATION_ADD) &&
          data.length > 0 && (
            <>
              {updatedDataSet.status_id !== 2 && (
                <Button
                  className="primaryBtn generateEstimateBtn"
                  disabled={
                    ((isActive || disable) && getDaysFromDate(data[0].processed_at) < 45)
                  }
                  onClick={generateEstimate}
                  size="large"
                >
                  <SyncOutlined />
                  <p>Regenerate</p>
                </Button>
              )}
              {updatedDataSet.status_id === 2 && (
                  <Tooltip title="Estimate unavailable. Make changes or wait before running again for updated insights">
                    <Button
                      className="primaryBtn generateEstimateBtn"
                      disabled={
                        getDaysFromDate(data[0].processed_at) < 45 || disable
                      }
                      onClick={generateEstimate}
                      size="large"
                    >
                      <SyncOutlined />
                      <p>Regenerate</p>
                    </Button>
                  </Tooltip>
                )}
            </>
          )}
      </div>

      {data.length > 0 &&
        data[0].is_valid === 0 &&
        data[0].error_code === 1  &&
        data[0].reason && (
          <EstimateAlert
            className="audienceAlertError"
            msgBody={<p>Error: {data[0].reason}</p>}
          />
        )}

      {data.length > 0 &&
        data[0].is_valid === 1 &&
        data[0].error_code === 1  &&
        data[0].reason  && (
          <EstimateAlert
            className="audienceAlertWarning"
            msgBody={<p>Warning: {data[0].reason}</p>}
          />
        )}

      <Table
        columns={dtcColumns}
        dataSource={manipulateDTCArray(data) ?? []}
        className={`antTableElement audienceListTable${
          isLoading ? ' hideNoDataLabel' : ''
        }`}
        locale={{
          emptyText: (
            <CustomEmptyTableComponent message="This audience has not been estimated yet." />
          ),
        }}
        loading={isLoading}
        pagination={false}
        sortDirections={['ascend', 'descend', 'ascend']}
        scroll={data.length > 0 ? { x: 'max-content' } : undefined}
      />
    </>
  );
};

export default AudienceEstimateTable;
