import React, { FC } from 'react';

const EstimateAlert: FC<{
  msgBody: React.ReactNode;
  className: string;
}> = ({ msgBody, className }) => {
  return (
    <div className={`audienceAlert ${className}`}>
      <span className="leftIcon"></span>
      {msgBody}
    </div>
  );
};

export default EstimateAlert;
