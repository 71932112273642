/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactNode, useEffect, useState } from 'react';
import { ModalComponent } from '../../../../components/sharedComponent';
import {
  useCheckIfDataModifiedStore,
  useCheckSavingEnabilityStore,
  useFeatureSetDataCheckStore,
  useFeatureSetDataHandlingStore,
  useLoaderStore,
} from '../../../../stores';
import {
  IAudienceSaveMethods,
  IAudienceSetUpState,
  IAudienceState,
  ICaregiverFeatureState,
  ICheckIfDataModifiedState,
  IEnableSaveState,
  IFeatureSetDataCheckState,
  IFeatureSetDataState,
  IGlobalFeatureState,
  IHcpFeatureState,
  ILoaderState,
} from '../../../../models/interfaces';
import { Button, Dropdown, MenuProps } from 'antd';
import {
  useAudienceSaveMethods,
  useAudienceSetUp,
  useAudienceSets,
  useCareGiverFeatureSet,
  useGlobalFeatureSet,
  useHcpFeatureSet,
} from '../../../../stores/audienceSets.store';
import { useRedirection, useNotificationMessage } from '../../../../hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';

type SavingOptionType = {
  key: '1' | '2' | '3' | '4';
  label: string;
  messagecontent?: string;
};

export const savingOptions: SavingOptionType[] = [
  {
    key: '1',
    label: 'Save',
    messagecontent: 'Audience is saved',
  },
  {
    key: '2',
    label: 'Save and Exit',
    messagecontent: 'Audience is saved',
  },
  {
    key: '3',
    label: 'Build Audience Insights',
  },
];

export const defineOptions = {
  define: [
    { key: '1', label: 'Save', messagecontent: 'Audience is saved' },
    { key: '2', label: 'Save and Exit', messagecontent: 'Audience is saved' },
    { key: '3', label: 'Build Audience Insights' },
  ],
  estimate: [
    { key: '1', label: 'Save', messagecontent: 'Audience is saved' },
    { key: '2', label: 'Save and Exit', messagecontent: 'Audience is saved' },
    { key: '3', label: 'View Audience Insights' },
    { key: '4', label: 'Activate' },
  ],
};

export const reviewOptions = {
  notEstimate: [{ key: '1', label: 'Edit Audience Definition' }],
  estimate: [
    { key: '1', label: 'Edit Audience Definition' },
    { key: '2', label: 'Activate' },
  ],
  activate: [{ key: '1', label: 'Activate' }],
};

export const activateOptions = {
  estimate: [
    { key: '1', label: 'Edit Audience Definition' },
    { key: '2', label: 'View Audience Insights' },
  ],
  activate: [
    { key: '1', label: 'View Audience Definition' },
    { key: '2', label: 'View Audience Insights' },
  ],
};

const AudienceSetupFieldsWrap: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [modalToOpenForCancel, setModalToOpenForCancel] =
      useState<boolean>(false),
    [messageObj, setMessageObj] = useState<any>({ isShowing: false });

  const { ifDataModified } = useCheckIfDataModifiedStore(
      (state: ICheckIfDataModifiedState) => state
    ),
    { loaderState } = useLoaderStore((state: ILoaderState) => state),
    {
      advertiser,
      audienceTitle,
      audienceBrand,
      audienceAgency,
      setAdvertiser,
      setAudienceAgency,
      setAudienceBrand,
      setAudienceTitle,
      selectedAudienceType,
      setSelectedAudienceType,
    } = useAudienceSetUp((state: IAudienceSetUpState) => state),
    { setIsSaveInitiated } = useAudienceSaveMethods(
      (state: IAudienceSaveMethods) => state
    ),
    { ifUpdated } = useFeatureSetDataCheckStore(
      (state: IFeatureSetDataCheckState) => state
    ),
    {
      selectedSavingOpt,
      setSelectedSavingOpt,
      setDefineFeatureData,
      setDefineFeatureCount,
      setDefineFeatureSet,
    } = useAudienceSets((state: IAudienceState) => state),
    { updatedDataSet } = useFeatureSetDataHandlingStore(
      (state: IFeatureSetDataState) => state
    ),
    { setHcpFeatureSet, setHcpTargeting } = useHcpFeatureSet(
      (state: IHcpFeatureState) => state
    ),
    { setCaregiverFeatureSet, setCaregiverTargeting } = useCareGiverFeatureSet(
      (state: ICaregiverFeatureState) => state
    ),
    { setGlobalFeatureSet, setGlobalTargeting } = useGlobalFeatureSet(
      (state: IGlobalFeatureState) => state
    ),
    {
      setIsEnableSaveForEachSet,
      setIisFeatureSetsFilled,
    } = useCheckSavingEnabilityStore((state: IEnableSaveState) => state);
  const { redirectAudienceListing } = useRedirection();

  const { destroyMessage } = useNotificationMessage(messageObj);

  useEffect(() => {
    return () => {
      setAudienceTitle('');
      setSelectedAudienceType(null);
      setIisFeatureSetsFilled({});
      setIsEnableSaveForEachSet({})
      setAudienceAgency(null);
      setAudienceBrand(null);
      setAdvertiser(null);
      setDefineFeatureData([]);
      setHcpTargeting([]);
      setDefineFeatureData([]);
      setSelectedSavingOpt('');
      setHcpFeatureSet({
        selectedProviderSpecialty: [],
        customNPIList: [],
        selectedProviderTaxonomy: [],
      });
      setDefineFeatureCount(0);
      setCaregiverTargeting([]);
      setCaregiverFeatureSet({
        caregiverGender: null,
        caregiverAge: [],
        selectedGeographyDmas: [],
        selectedGeographyStates: [],
      });
      setDefineFeatureSet({});
      setGlobalFeatureSet({
        timeFrame: null,
        dateRange: null,
        patientGender: null,
        selectedAge: [],
        selectedRaceAndEthnicity: [],
        selectedGeographyDmas: [],
        selectedGeographyStates: [],
        language: [],
      });
      setGlobalTargeting([]);
    };
  }, []);

  useEffect(() => {
    if (ifUpdated) {
      const {
        title,
        audience_type_id,
        advertiser_id: advertiser,
        agency_id: audience_agency,
        brand_id: audience_brand,
      } = updatedDataSet;
      setAdvertiser(advertiser);
      setAudienceAgency(audience_agency);
      setAudienceBrand(audience_brand);
      setAudienceTitle(title);
      setSelectedAudienceType(audience_type_id);
    }
  }, [ifUpdated, pathname]);

  const onSavingMenuClick: MenuProps['onClick'] = (e) => {
    setIsSaveInitiated(false);
    destroyMessage('save');
    setMessageObj({ isShowing: false });

    switch (getActiveIndexes()) {
      case 1:
        handlePageOne(e);
        break;
      case 2:
        handlePageTwo(e);
        break;
      case 3:
        handlePageThree(e);
        break;
    }
  };

  const handlePageOne = (e: any) => {
    setSelectedSavingOpt(e.key);
    if(pathname.includes('create') || pathname.includes('define')) setIsSaveInitiated(true);
  };

  const handlePageTwo = (e: any) => {
    if (updatedDataSet.activation_list.length === 0) {
      if (e.key === '1') {
        navigate(`/audience/${updatedDataSet.audience_id}/define`);
      } else {
        navigate(`/audience/${updatedDataSet.audience_id}/activate`);
      }
    } else {
      navigate(`/audience/${updatedDataSet.audience_id}/activate`);
    }
  };

  const handlePageThree = (e: any) => {
    if (updatedDataSet.activation_list.length === 0) {
      if (e.key === '1') {
        navigate(`/audience/${updatedDataSet.audience_id}/define`);
      } else if (e.key === '2') {
        navigate(`/audience/${updatedDataSet.audience_id}/review`);
      }
    } else {
      if (e.key === '1') {
        navigate(`/view-audience/${updatedDataSet.audience_id}`);
      }
      navigate(`/audience/${updatedDataSet.audience_id}/review`);
    }
  };

  const handleModalCancel = () => {
    setAudienceTitle('');
    setAudienceAgency(null);
    setAudienceBrand(null);
    setAdvertiser(null);
    redirectAudienceListing();
    setSelectedAudienceType(null);
    setDefineFeatureData(null);
  };

  const handleCancel = () => {
    if (ifDataModified) {
      setModalToOpenForCancel(true);
    } else {
      setAudienceTitle('');
      setAudienceAgency(null);
      setAudienceBrand(null);
      setAdvertiser(null);
      redirectAudienceListing();
      setSelectedAudienceType(null);
      setDefineFeatureData([]);
    }
  };

  const getActiveIndexes = () => {
    if (pathname.includes('define') || pathname.includes('create')) return 1;
    else if (pathname.includes('review')) return 2;
    else if (pathname.includes('activate')) return 3;
    else return 0;
  };

  const getItems = () => {
    switch (getActiveIndexes()) {
      case 1:
        return getDefineOptions();
      case 2:
        return getReviewOptions();
      default:
        return getActivateOptions();
    }
  };

  const estimatePending = () => {
    if (
      !updatedDataSet.estimated_info ||
      (updatedDataSet?.estimated_info &&
        updatedDataSet?.estimated_info[0]
          ?.status === 'Pending')
    )
      return reviewOptions.notEstimate;
    else return reviewOptions.estimate;
  };

  const getDefineOptions = () => {
    return updatedDataSet?.estimated_info
      ? defineOptions.estimate
      : defineOptions.define;
  };

  const getReviewOptions = () => {
    return updatedDataSet?.activation_list?.length === 0
      ? estimatePending()
      : reviewOptions.activate;
  };

  const getActivateOptions = () => {
    return updatedDataSet?.activation_list?.length === 0
      ? activateOptions.estimate
      : activateOptions.activate;
  };

  return (
    <>
      <div className="audienceCommonWrap">
        {!loaderState && <>{children}</>}
      </div>
      <div className="newFeatureSetBtn">
        <div className="editAudienceNewFeatureSetBtn">
          <ModalComponent
            modalHeader={'Cancel ' + (id ? 'Update' : 'Create') + ' Audience'}
            modalToOpen={modalToOpenForCancel}
            setModalToOpen={setModalToOpenForCancel}
            deleteSetParams={{
              name: 'Cancel ' + (id ? 'Update' : 'Create') + ' Audience',
              handelOk: handleModalCancel,
              message:
                'You’re about to lose any unsaved changes. Do you want to continue?',
              okText: 'Yes',
              cancelText: 'No',
            }}
          />
          {(pathname.includes('create') || pathname.includes('define')) && (
            <Button
              type="text"
              size="large"
              onClick={handleCancel}
              className="noBGBtn"
            >
              Cancel
            </Button>
          )}
          <Dropdown
            className="dropdownSaveButton"
            trigger={['click']}
            disabled={
              (pathname.includes('create') || pathname.includes('define')) &&
              !(
                selectedAudienceType &&
                audienceTitle &&
                audienceAgency &&
                audienceBrand &&
                advertiser
              )
            }
            menu={{
              items: getItems(),
              defaultSelectedKeys: [selectedSavingOpt],
              onClick: onSavingMenuClick,
            }}
          >
            <Button type="primary" size="large">
              Audience Actions
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
    </>
  );
};

export default AudienceSetupFieldsWrap;
